<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['order_list'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <template v-for="(shop_id, shop_index) in d_productList.param_order">
      <b-row>
          <b-col sm="12" lg="2"></b-col>
          <b-col sm="12" lg="8">
            <b-row style="height: 100px;">
              <b-col cols="12">
                <img :src="d_productList.parameters[shop_id].data.data['0']['2']['lang'][StoreLang]" style="width: 50px; padding: 5px;">
                <strong style="font-size: 20px;">{{ d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang] }}</strong>
              </b-col>
            </b-row>
<!--             <b-row>
              <b-col cols="12">
               <small style="color: blue;">{{ d_productList.parameters[shop_id].data.data['0']['3']['lang'][StoreLang] }}</small>
              </b-col>
            </b-row> -->
          </b-col>
          <b-col sm="12" lg="2"></b-col>
      </b-row>
      <template v-for="(bundle_group_id, bundle_group_index) in d_productList.parameters[shop_id].groups.param_order">
        <b-row>
            <b-col sm="12" lg="2"></b-col>
            <b-col sm="12" lg="8">
              <b-row style="padding: 5px; text-align: left; font-size: 20px;">
                <b-col cols="12">
                  <strong> {{ d_productList.parameters[shop_id].groups.parameters[bundle_group_id]['name'][StoreLang].toLocaleUpperCase() }}</strong>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" lg="2"></b-col>
        </b-row>
        <b-row>
            <b-col sm="12" lg="2"></b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin-bottom: 50px;">
                <b-col v-for="(bundle_id, bundle_id_index) in d_productList.parameters[shop_id].groups.parameters[bundle_group_id].id_list" cols="12" sm="12" lg="3" style="padding: 20px;">
                  <b-card class="product-card" @click="f_goToBundleDetails(d_productList.parameters[shop_id].data.client_id, d_productList.parameters[shop_id].parameters[bundle_id].data.client_id)">
                    <!-- background-image: linear-gradient(90deg, #f7fcfd, #e8f6f9, #d5efed, #b7e4da, #8fd4c1, #69c2a3, #49b17f, #2f995a, #157f3c, #036429, #036429); -->
                    <b-row style="margin: 3px; min-height: 75px; text-align: center;">
                      <b-col sm="12" md="12">
                        <strong> {{ d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['1']['lang'][StoreLang] }} </strong>
                      </b-col>
                    </b-row>
                    <b-row style="margin: 3px; min-height: 50px; text-align: center; font-size: 30px;">
                      <b-col sm="12" md="12">
                        <template v-if="d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['13'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['13']['val'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['13']['val']['value'] === '1'">
                          {{ f_calculateBundleTotalPrice(d_productList.parameters[shop_id].parameters[bundle_id]) }}
                        </template>
                        <template v-if="d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['12'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['12']['val'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['12']['val']['value'] === '1'">
                          {{ StoreLangTranslation.data['available_soon'][StoreLang] }}
                        </template>
                      </b-col>
                    </b-row>
                    <template v-if="d_productList.parameters[shop_id].parameters[bundle_id].param_order.length > 0">
                        <template v-for="(product_id, product_id_index) in d_productList.parameters[shop_id].parameters[bundle_id].param_order">
                          <b-row v-if="d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'][StoreLang]" style="margin: 0px; border-bottom: solid 1px #eaeaea; padding: 3px; text-align: center;">
                            <b-col sm="12" md="12">
                              <strong style="font-size:20px;">{{ d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'][StoreLang] }}</strong>
                              <small>
                               {{ d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['10']['lang'][StoreLang] }}
                              </small>
                            </b-col>
                          </b-row>
                        </template>
                      </template>
                    <template v-if="false && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'].list && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'].list.length > 0">
                        <template v-for="(feature_item, feature_item_index) in d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2']['list']">
                          <b-row v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['1'].indexOf(feature_item['11'].val.value) !== -1" style="margin: 0px; border-bottom: solid 1px #eaeaea; padding: 3px; text-align: center;">
                            <b-col sm="12" md="12">
                              <small> {{ feature_item['3']['lang'][StoreLang] }} </small>
                            </b-col>
                          </b-row>
                        </template>
                      </template>
                  </b-card>
<!--                   <b-row style="margin: 0px; padding: 5px; height: 40px; background-color: #dbfbf5; border-radius: 0px 0px 10px 10px; text-align: center; cursor: pointer;" @click="f_goToBundleDetails(d_productList.parameters[shop_id].data.client_id, d_productList.parameters[shop_id].parameters[bundle_id].data.client_id)">
                    <b-col sm="12" md="9">
                      {{ StoreLangTranslation.data['details'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" md="3">
                       <i class="fa fa-angle-right float-right font-lg"></i>
                    </b-col>
                  </b-row>
 -->                </b-col>
              </b-row>
              <hr>
            </b-col>
            <b-col sm="12" lg="2"></b-col>
        </b-row>
      </template>
      <b-row>
        <b-col sm="12" lg="2"></b-col>
        <b-col sm="12" lg="8">
          <template v-if="d_productList.parameters[shop_id].no_groups && d_productList.parameters[shop_id].no_groups.id_list && d_productList.parameters[shop_id].no_groups.id_list.length > 0">
            <b-row style="margin-bottom: 20px;">
              <b-col v-for="(no_group_id, no_group_id_index) in d_productList.parameters[shop_id].no_groups.id_list" cols="12" sm="12" lg="4">
                <b-card footer-class="px-3 py-2" style="height: 100%;">
                  <b-row style="margin: 5px;">
                    <b-col sm="12" md="12">
                      <strong> {{ d_productList.parameters[shop_id].parameters[no_group_id].data.data['0']['1']['lang'][StoreLang] }} </strong>
                    </b-col>
                  </b-row>
                  <div slot="footer">
                    <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_goToBundleDetails(x.key)">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </template>
        </b-col>
        <b-col sm="12" lg="2"></b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'product_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_productList: '',
    };
  },
  created: function () {
    // this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_bundleAndProductListActive();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
  },
  methods: {
    f_calculateBundleTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_id in bundle_products.parameters) {
        try {
          for (let currency_amount_index in bundle_products.parameters[product_id].data.data['0']['25'].list) {
            let currency_amount_item = bundle_products.parameters[product_id].data.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_goToBundleDetails: function (user_shop_client_id, user_shop_bundle_client_id) {
      this.$router.push({ path: ClsRoute.f_getRoutePath('product_details', this.StoreLang), query: { 'user_shop_client_id': user_shop_client_id, 'user_shop_bundle_client_id': user_shop_bundle_client_id } });
    },
    f_bundleAndProductListActive: function () {
      let query = '';
      let data = {};
      ServiceFunctions.bundle_and_product_list_active(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_productList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

<style type="text/css">
.product-card {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(90deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  border: none;
  box-shadow: 0px 0px 3px 3px #bcb1b1;
}  
.product-card:hover {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(120deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  margin: -3px;
  border: none;
  box-shadow: 0px 0px 10px 10px #bcb1b1;
}  
</style>

